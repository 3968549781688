import { Auth } from 'types/Auth';
import { Sale, SubscriptionInfo } from 'types/Sale';
import { UserWallet } from 'types/Wallet';
import { SALE_MAP, TXN_MAP } from 'types/constants';
const { SUBSCRIPTION_STATUS_TYPES } = SALE_MAP;

export const saleSelectors = {
  domainIdSelector(sale: Sale, auth: Auth): string | void {
    if (auth && auth.realmId) {
      return `INVOICE:${auth.realmId}_${localIdSelector(sale)}`;
    }
  },
  localIdSelector,
  gratuityEnabledSelector(sale: Sale) {
    return sale && sale.receivable && sale.receivable.gratuityEnabled;
  },
  idSelector(sale: Sale) {
    return sale && sale.id;
  },
  txnDateSelector(sale: Sale) {
    return sale.txnDate;
  },
  dutyAmountSelector(sale: Sale) {
    return sale.duty && sale.duty.amount;
  },
  descriptionSelector(sale: Sale) {
    return sale.description;
  },
  amountSelector(sale: Sale) {
    return sale.amount;
  },
  achOnlineConvenienceFeeEnabledSelector(sale: Sale) {
    return sale?.convenienceFee?.ach?.enabled;
  },
  achOnlineConvenienceFeeAmountSelector(sale: Sale) {
    return sale?.convenienceFee?.ach?.feeAmount;
  },
  achOnlineConvenienceFeeAmountPaidSelector(sale: Sale) {
    return sale?.convenienceFee?.ach?.feeAmountPaid;
  },
  depositAmountSelector(sale: Sale) {
    return sale.requestedAmount;
  },
  balanceSelector(sale: Sale) {
    return sale && sale.receivable && sale.receivable.balance;
  },
  currencySelector(sale: Sale) {
    return sale && sale.currencyInfo && sale.currencyInfo.currency;
  },
  referenceNumberSelector(sale: Sale) {
    return sale.referenceNumber;
  },
  paymentDetailsMessageSelector(sale: Sale) {
    return sale.paymentDetailMessage;
  },
  dueDateSelector(sale: Sale) {
    return sale && sale.receivable && sale.receivable.dueDate;
  },
  typeSelector(sale: Pick<Sale, 'type'>) {
    return sale.type;
  },
  subTypeSelector(sale: Sale) {
    return sale.subType;
  },
  customerNameSelector(sale: Sale) {
    return sale.contact.displayName;
  },
  customerLocalNameIdSelector(sale: Sale): string | void {
    if (
      sale &&
      sale.contact &&
      sale.contact.entity &&
      sale.contact.entity.globalId &&
      sale.contact.entity.globalId.localId
    ) {
      return sale.contact.entity.globalId.localId;
    }
  },
  isScheduledPayment(sale: Sale) {
    return sale.scheduleInfo && sale.scheduleInfo.scheduleId;
  },
  isSchedulePay(sale: Sale) {
    return sale.scheduleInfo && sale.scheduleInfo.scheduleType === 'SCHEDULE_PAY';
  },
  isAutoPay(sale: Sale) {
    return sale.scheduleInfo && sale.scheduleInfo.scheduleType === 'AUTO_PAY';
  },
  isSubscription(sale: Sale) {
    return sale.type === 'SUBSCRIPTION';
  },
  isGpu(sale: Sale) {
    return sale.isGpu;
  },
  getScheduleDate(sale: Sale) {
    const { scheduleInfo } = sale;
    return scheduleInfo && scheduleInfo.scheduleDate && new Date(scheduleInfo.scheduleDate);
  },
  getScheduleInfo(sale: Sale) {
    return sale.scheduleInfo;
  },
  getRecurringInfo(sale: Sale) {
    return sale.recurringInfo;
  },
  estimateStatus(sale: Sale) {
    if (sale.acceptStatusInfo && sale.acceptStatusInfo.acceptStatus) {
      return sale.acceptStatusInfo.acceptStatus;
    }
    return sale.status;
  },
  estimateActionDate(sale: Sale) {
    if (sale.acceptStatusInfo && sale.acceptStatusInfo.acceptDate) {
      return sale.acceptStatusInfo.acceptDate;
    }
    return null;
  },
  lineItemsSelector(sale: Sale) {
    return sale.lines;
  },
  toEmailsSelector(sale: Pick<Sale, 'contact'>) {
    return sale?.contact?.toEmails || [];
  },
  taxAmountSelector(sale: Sale) {
    if (sale.tax && typeof sale.tax.totalTaxAmount === 'number') {
      return sale.tax.totalTaxAmount;
    }
    return 0;
  },
  customerOpenInvoicesSelector(sale: Sale) {
    return sale.customerOpenInvoices;
  },
  isOneToManyPaymentRequest(sale: Sale) {
    return (
      sale.type === TXN_MAP.TYPES.PAYMENT_REQUEST &&
      sale.subType === TXN_MAP.SUB_TYPES.PAYMENT_LINK_MULTIPLE
    );
  },
  estimatedDeliverySelector(sale: Sale) {
    return sale.estimatedDelivery;
  },
  customerJobIdSelector(sale: Sale) {
    if (sale && sale._customerJobId) {
      return sale._customerJobId;
    }
    return null;
  },
  customerJobNameSelector(sale: Sale) {
    if (sale && sale._customerJobName) {
      return sale._customerJobName;
    }
    return null;
  },
  subscriptionInfo(sale: Sale) {
    return (
      (sale.subscriptionInfo && sale.subscriptionInfo.subscriptionInfo) ||
      ({} as SubscriptionInfo['subscriptionInfo'])
    );
  },
  subscriptionStatus(sale: Sale) {
    return (
      sale.subscriptionInfo &&
      sale.subscriptionInfo.subscriptionInfo &&
      sale.subscriptionInfo.subscriptionInfo.status
    );
  },
  subscriptionPaymentInfo(sale: Sale) {
    return sale.subscriptionInfo && sale.subscriptionInfo.paymentInfo;
  },
  isSubscriptionActive(sale: Sale) {
    const { subscriptionInfo } = sale;
    if (subscriptionInfo && subscriptionInfo.subscriptionInfo) {
      const { status } = subscriptionInfo.subscriptionInfo;
      return (
        status === SUBSCRIPTION_STATUS_TYPES.ACTIVE || status === SUBSCRIPTION_STATUS_TYPES.ERROR
      );
    }
    return false;
  },
  isWalletUsedForActiveSubscription(sale: Sale, wallet: UserWallet): boolean | void {
    const paymentInfo = saleSelectors.subscriptionPaymentInfo(sale);

    if (paymentInfo && paymentInfo.walletInfo) {
      const {
        walletInfo: { token },
      } = paymentInfo;
      const { id } = wallet;
      return token === id;

      // That code for when we didnt had the token (wallet entry id) in the subscription info:
      // const decodeMaskedNumber = (masked, numOfDigits = 4) => {
      //   try {
      //     const [digits] = masked.match(/\d+/g);
      //     const firstLetter = masked.charAt(0);
      //     return `${firstLetter}${digits.slice(digits.length - numOfDigits)}`;
      //   } catch (e) {
      //     return false;
      //   }
      // };
      //
      // const { paymentMaskedCard } = paymentInfo;
      // const maskedNumber = getMaskedPaymentNumber(wallet);
      // const decodedCardInSubscription = decodeMaskedNumber(paymentMaskedCard);
      //
      // return (
      //   decodedCardInSubscription ===
      //   decodeMaskedNumber(maskedNumber, decodedCardInSubscription.length - 1)
      // );
    }
  },

  subscriptionChargeDate(sale: Sale) {
    const getNumberWithOrdinal = (n: number) => {
      let s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };
    const { startDate = '', term } = saleSelectors.subscriptionInfo(sale);

    const intervalDay = parseInt(startDate.split('-')[2] || '1', 10);
    const ordinal = getNumberWithOrdinal(intervalDay);

    return {
      intervalDay,
      ordinal,
      term,
    };
  },
};

function localIdSelector(sale: Sale): Sale['entity']['globalId']['localId'] | void {
  if (sale && sale.entity && sale.entity.globalId && sale.entity.globalId.localId) {
    return sale.entity.globalId.localId;
  }
}
